import { logger } from '../logger/Logger';

const LMV_ANALYTICS_DEFAULT = 'mixpanel';

class ViewerAnalytics {
  constructor() {
    this.providers = {};
    this.defaultProvider = LMV_ANALYTICS_DEFAULT;
  }

  /**
   * 
   * @param {object} props - properties that are sent with every track request 
   */
  setSuperProps(props) {
    this.superProps = props;
  }

  register(provider) {

    const name = provider.name.toLowerCase();

    if (name in this.providers) {
      return; // already registered
    }

    try {
      provider.init();
      if (this.superProps) {
        provider.register(this.superProps);
      }
      this.providers[name] = provider;
    } catch (e) {
      logger.warn(`Failed to register analytics provider: "${provider.name}". ${e}`);
    }
  }

  optIn(options, names = 'all') {
    this._getProviders(names).forEach((p) => p.optIn(options));
  }

  optOut(options, names = 'all') {
    this._getProviders(names).forEach((p) => p.optOut(options));
  }

  setDefaultProvider(name = '') {
    name = name.toLowerCase();
    if (!(name in this.providers)) {
      logger.warn('Provider not registered');
      return;
    }
    this.defaultProvider = name;
  }

  hasOptedOut(name) {
    name = name || this.defaultProvider;
    name = name.toLowerCase();
    if (!(name in this.providers)) {
      logger.warn('Provider not registered');
      return;
    }
    return this.providers[name].hasOptedOut();
  }

  getDistinctId(name) {
    name = name || this.defaultProvider;
    name = name.toLowerCase();
    if (!(name in this.providers)) {
      logger.warn('Provider not registered');
      return;
    }
    return this.providers[name].getDistinctId();
  }

  track(event, properties, names = 'all') {
    this._getProviders(names).forEach((p) => p.track(event, properties));
  }

  identify(distinctId, names = 'all') {
    this._getProviders(names).forEach((p) => p.identify(distinctId));
  }

  _getProviders(names) {
    let keys;
    if (Array.isArray(names)) {
      keys = names.map((x) => x.toLowerCase()).filter((x) => !(x in this.providers));
    } else if (names === 'all') {
      keys = Object.keys(this.providers);
    } else {
      names = names.toLowerCase();
      keys = names in this.providers ? [names] : [];
    }

    return keys.map((k) => this.providers[k]);
  }
}

// default storage is cookie
const instance = new ViewerAnalytics();

export { instance as analytics };
