
//TODO_TS: Rename this to singular
export var MeasurementTypes = {
    MEASUREMENT_DISTANCE:  1, // Measurement from point to point, not matter what geometry it is.
    MEASUREMENT_ANGLE: 2,
    MEASUREMENT_AREA: 3,
    CALIBRATION: 4,
    MEASUREMENT_CALLOUT: 5,
    MEASUREMENT_LOCATION: 6
};

