export default class AnalyticsProviderInterface {
	constructor(options = {}) {
		this.options = options;
	}
	/**
	 * Called by ViewerAnalytics to register this provider
	 * Implementer should throw an error on failure
	 */
	init() {}

	/**
	 * Called after init. This could be used to register super properties
	 */
	register() {} 
	get name() {}
	optIn(options) {}
	optOut(options) {}
	hasOptedOut() {}
	getDistinctId() {}
	track(event, properties) {}
	identify(distinctId) {}
}

const namespace = AutodeskNamespace('Autodesk.Viewing.Private');
namespace.AnalyticsProviderInterface = AnalyticsProviderInterface;
