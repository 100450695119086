import SheetRenderVert from './shaders/sheet_render_vert.glsl';
import SheetRenderFrag from './shaders/sheet_render_frag.glsl';

// Used for pre-rendering the sheet into a different target, before placing it into the 3D scene.
export default class SheetRenderContext {
    constructor(viewerImpl, renderContext, glRenderer, materialManager) {
        this.viewerImpl = viewerImpl;
        this.renderContext = renderContext;
        this.glRenderer = glRenderer;

        this.materialManager = materialManager;
        this.materialName = '__material_2Don3D__';
        this.tmpVec = new THREE.Vector3();
    }

    createContext() {
        this.sheetContext = new Autodesk.Viewing.Private.RenderContext();
        this.sheetContext.init(this.glRenderer, this.renderContext.settings.logicalWidth, this.renderContext.settings.logicalHeight, { offscreen: true });

        const config = this.renderContext.getConfig();
        // Disable unneeded things. Set background color to transparent.
        config.antialias = false;
        config.aoEnabled = false;
        config.renderEdges = false;
        config.clearAlpha = 0;
        if (config.clearColor) {
            config.clearColor.set(0, 0, 0);
        } else {
            config.clearColorBottom.set(0, 0, 0);
            config.clearColorTop.set(0, 0, 0);
        }
        this.sheetContext.applyConfig(config);
    }

    setSize(context = this.renderContext) {
        if (!this.sheetContext) {
            return;
        }

        this.sheetContext.setSize(context.settings.logicalWidth, context.settings.logicalHeight);
        this.updateMaterial();
    }

    // Creates a scene for the plane geometry to sit on
    createScene() {
        this.setSize();

        this.scene = new THREE.Scene();
        // Create unit size mesh. The size will be set by setting the scale
        this.planeGeometry = new THREE.PlaneBufferGeometry(1, 1);

        this.material = new THREE.ShaderMaterial({
            uniforms: {
                sheetMap: { type: 't', value: this.sheetContext.getColorTarget() },
                idMap: { type: 't', value: this.sheetContext.getIDTargets()[0] },
                modelIDv2: { type: 'v3', value: new THREE.Vector3() },
                resolution: { type: 'v2', value: new THREE.Vector2() },
            },
            vertexShader: SheetRenderVert,
            fragmentShader: SheetRenderFrag,
            side: THREE.DoubleSide,
            transparent: true
        });

        this.updateMaterial();

        this.materialManager.addMaterialNonHDR(this.materialName, this.material);

        this.mesh = new THREE.Mesh(this.planeGeometry, this.material);
        this.mesh.matrixAutoUpdate = false;
        this.scene.add(this.mesh);
    }

    updateMaterial() {
        if (this.material) {
            const target = this.sheetContext.getColorTarget();
            // Update because in resize the targets will be recreated
            this.material.uniforms.sheetMap.value = target;
            this.material.uniforms.idMap.value = this.sheetContext.getIDTargets()[0];
            this.material.uniforms.resolution.value.set(1 / (target.width || 1), 1 / (target.height || 1));
        }
    }

    beginScene(scene, camera, lights) {
        if (!this.sheetContext) {
            this.createContext();
        }

        this.sheetContext.beginScene(scene, camera, lights, true);
    }

    // The sheet is rendered onto a separate buffer, without depth testing. This buffer is then used as a texture
    // which is projected onto a plane, that matches the pose and size of the original plane. This way, we can have
    // depth testing for the sheet so it blends correctly with the 3D model, while avoiding artifacts created by
    // rendering the sheet regularly with depth testing on
    // Optional context is e.g. for printing where the context is different
    renderScenePart(originalScene, wantColor, wantID, context = this.renderContext) {
        if (!originalScene.frags || !originalScene.frags.is2d) {
            return;
        }

        if (!this.scene) {
            this.createScene();
        }

        this.glRenderer.pushViewport();

        const modelId = originalScene.modelId;
        const model = this.viewerImpl.modelQueue().findModel(modelId);
        const bounds = model.getBoundingBox(true);
        const modelTransform = originalScene.frags.matrix;
        const placementTransform = model.loader?.options?.placementTransform;
        // Encode model id into vector
        this.material.uniforms.modelIDv2.value.set((modelId & 0xFF) / 255,
                                                ((modelId >> 8) & 0xFF) / 255,
                                                ((modelId >> 16) & 0xFF) / 255);

        // Render original scene to separate buffer
        this.sheetContext.renderScenePart(originalScene, wantColor, false, wantID, false);
        this.sheetContext.presentBuffer();

        this.glRenderer.popViewport();

        // Update mesh with this scene's model's bounds
        // First set original bounds and location
        // Then apply transform
        const size = bounds.size();
        this.mesh.matrix.makeScale(size.x, size.y, 1.0);
        bounds.center(this.tmpVec);
        this.mesh.matrix.setPosition(this.tmpVec);
        if (placementTransform) {
            this.mesh.matrix.multiplyMatrices(placementTransform, this.mesh.matrix);
        }
        if (modelTransform) {
            this.mesh.matrix.multiplyMatrices(modelTransform, this.mesh.matrix);
        }
        this.mesh.matrixWorld.copy(this.mesh.matrix);
        // Now render mesh with projected texture
        this.scene.modelId = modelId;
        context.renderScenePart(this.scene, wantColor, false, wantID);
    }

    destroy() {
        if (this.material) {
            this.material.dispose();
            this.material = null;
        }

        if (this.planeGeometry) {
            this.planeGeometry.dispose();
            this.planeGeometry = null;
        }

        if (this.sheetContext) {
            this.sheetContext.cleanup();
            this.context = null;
        }

        this.renderContext = null;
        this.glRenderer = null;

    }
}
